<template>
  <div>
    <!-- Filters -->
    <radio-station-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
        @filter="fetchRadioStation"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @change="changePageRadioStation({page:1 , itemsPerPage: $event})"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table
          class="position-relative pb-3"
          :items="radioStations"
          responsive
          :fields="tableColumns"
          :per-page="perPage"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <!-- Column: Radio -->
        <template #cell(radio)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.thumbnail"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <b-link
                    :to="{
                    name: 'apps-musics-view',
                    params: { id: data.item.id },
                  }"
                    class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name_ar }}
                </b-link>
                <small class="text-muted">{{ data.item.name }}</small>
              </div>
              <div class="ml-1">
                <b-overlay
                    :show="playMusicLoading"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="danger"
                    class="d-block"
                >
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="danger"
                      @click="playMusic(data.item.link)"
                  >
                    play
                  </b-button>
                </b-overlay>
              </div>
            </div>
          </b-media>
        </template>
        <!-- Column: Description -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
                data.item.description
                    ? `${data.item.description.slice(0, 60)} ...`
                    : ""
              }}</span>
          </div>
        </template>
        <!-- Column: Link -->
        <template #cell(link)="data">
          <div class="text-nowrap">
            <span>
              <i class="WMi-link"/>
            </span>
            <component
                :is="data.item.link ? 'b-link' : 'span'"
                :href="data.item.link"
                class="align-text-top text-capitalize"
            >{{ data.item.link ? data.item.link : "not link" }}
            </component>
          </div>
        </template>
        <!-- Column: Artist -->
        <template #cell(artist)="data">
          <div class="text-nowrap d-flex align-items-center">
            <span>
              <i class="WMi-user"/>
            </span>
            <b-link
                :to="{
                name: 'apps-artists-view',
                params: { id: data.item.artist.id },
              }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.artist.name }}
            </b-link>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
                :to="{
                name: 'apps-radio-station-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="updateSort(data.item.id)">
              <feather-icon icon="ArrowUpIcon"/>
              <span class="align-middle ml-50">Send to top</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteRadioStation(data.item.id)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
                class="text-muted"
            >Showing {{ pagination.pageStart }} to
              {{ pagination.pageStop }} of
              {{ pagination.itemsLength }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="pagination.itemsLength"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePageRadioStation({ page: $event , itemsPerPage: perPage})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BOverlay,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, onMounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import RadioStationListFilters from './RadioStationListFilters.vue'
import useRadioStationList from './useRadioStationList'
import userStoreModule from '../user/userStoreModule'
import RadioStationRepository from "@/abstraction/repository/radioStationRepository";

const radioStationRepository = new RadioStationRepository()
export default {
  components: {
    RadioStationListFilters,
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    playMusicLoading: false,
    isPlay: false,
    audio: null,
  }),
  setup() {
    const {
      fetchRadioStation,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      pagination,
      radioStations,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      deleteRadioStation,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useRadioStationList()
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'Pending', value: 'pending'},
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'},
    ]

    onMounted(() => {
      fetchRadioStation()
    })
    const changePageRadioStation = async paginate => {
      await fetchRadioStation(paginate)
    }

    const updateSort = async id => {
      await radioStationRepository.updateSort(id)
      await fetchRadioStation({page: currentPage, perPage: perPage})
    }

    return {
      // Sidebar
      radioStations,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      changePageRadioStation,
      fetchRadioStation,
      updateSort,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      deleteRadioStation,

      roleOptions,
      planOptions,
      statusOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async playMusic(url) {
      try {
        if (this.isPlay) {
          this.audio.pause()
          this.isPlay = false
          return
        }
        this.playMusicLoading = true
        this.audio = await new Audio(url)
        if (this.audio.paused) {
          this.audio.play()
          this.isPlay = true
        }
      } catch (e) {
        console.log(e)
        return e
      } finally {
        console.log('finally', this.audio.paused)
        this.playMusicLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
